import { createSelector } from 'reselect'
import { get } from 'lodash'
import { NAMESPACE } from './constants'
import { IPaymentsModel } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getPaymentsState = (state: any): IPaymentsModel => state[NAMESPACE]

export const getPaymentsSchema = createSelector(
  getPaymentsState,
  payments => payments.schema,
)

export const getPaymentsSchemaMethods = createSelector(
  getPaymentsSchema,
  schema => schema?.methods || [],
)

export const getPaymentIds = createSelector(
  [getState, getPaymentsState],
  (state, payments) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(payments, `sites[${sitePath}].allIds`, [])
  },
)

export const getPayments = createSelector(
  [getState, getPaymentsState],
  (state, payments) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(payments, `sites[${sitePath}].byId`, {})
  },
)

export const getPaymentFilters = createSelector(
  [getState, getPaymentsState],
  (state, payments) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(payments, `sites[${sitePath}].byFilters`, {})
  },
)

export const getPaymentsByFilter = createSelector(
  [getState, getPaymentsState],
  (state, payments) => (filter: string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const paymentsByFilter = get(
      payments,
      `sites[${sitePath}].byFilters[${filter}]`,
      [],
    )
    return paymentsByFilter.map((paymentId: number) =>
      getPaymentById(state)(paymentId),
    )
  },
)

export const getPaymentsByPage = createSelector(
  [getState, getPaymentsState],
  (state, payments) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const paymentsByPage = get(
      payments,
      `sites[${sitePath}].byPage[${page}]`,
      [],
    )
    return paymentsByPage.map((paymentId: number) =>
      getPaymentById(state)(paymentId),
    )
  },
)

export const getPaymentById = createSelector(
  getPayments,
  payments => (id: number | string) => get(payments, `[${id}]`, null),
)

export const isLoading = createSelector(
  [getState, getPaymentsState],
  (state, payments) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(payments, `sites[${sitePath}].isLoading`, false)
  },
)

export const getPages = createSelector(
  [getState, getPaymentsState],
  (state, payments) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(payments, `sites[${sitePath}].pages`, false)
  },
)
