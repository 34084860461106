import React from 'react'
import { titlePrefix } from './constants'
import { TRoute } from './types'

export const publicRoutes: TRoute[] = [
  {
    key: 'root',
    path: '/',
    exact: true,
    title: `${titlePrefix} | Login`,
    Component: () => React.lazy(() => import('../components/pages/LoginPage')),
  },
  {
    key: 'login',
    path: '/login',
    exact: true,
    title: `${titlePrefix} | Login`,
    Component: () => React.lazy(() => import('../components/pages/LoginPage')),
  },
  {
    key: 'forgotPassword',
    path: '/password-forgot',
    exact: true,
    title: `${titlePrefix} | Forgot Password`,
    Component: () =>
      React.lazy(() => import('../containers/ForgotPasswordPageContainer')),
  },
  {
    key: 'forgotPasswordSuccess',
    path: '/password-forgot-success',
    exact: true,
    title: `${titlePrefix} | Password Successfully Reset`,
    Component: () =>
      React.lazy(
        () => import('../containers/ForgotPasswordSuccessPageContainer'),
      ),
  },
  {
    key: 'resetPassword',
    path: '/password-reset',
    exact: true,
    title: `${titlePrefix} | Reset Password`,
    Component: () =>
      React.lazy(() => import('../containers/ResetPasswordPageContainer')),
  },
]
