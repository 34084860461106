import * as React from 'react'
import styled from 'styled-components'
import splitStringAndUpperFirst from '../../../utils/splitStringAndUpperFirst'
import { DataTable, Text, Button, Anchor } from '..'
import { Box } from 'grommet'
import UsersTemplate from '../templates/UsersTemplate'
import { uiUsersConstants } from '../../../state/modules/ui/users'
import { usersInterfaces, usersSelectors } from '../../../state/modules/users'
import { useSelector } from 'react-redux'

interface IUsersPageProps {
  className?: string
  pages: TPages
  isLoadingUsers: boolean
  match: any
  currentPage: number
  currentChannel: number | null
  userData: IUserRow[]
  currentUserEmail: string
  currentUserId: number | null
  currentSitePath: string
  usersSchemaMethods: string[]
  fetchUsers(params: usersInterfaces.IRequestUsersParams, admin?: boolean): void
  setCurrentPage(page: number): void
  redirect(path: string): void
  url: string
}

export interface IUserRow {
  id: number
  name: string
  user_email: string
  type: string
}

class UsersPage extends React.Component<IUsersPageProps, {}> {
  public componentDidMount() {
    const { fetchUsers, currentPage } = this.props
    // Get users for initial page. Subsequent requests are handled by ui/users/epics
    const defaultParams = {
      per_page: uiUsersConstants.perPage,
      page: currentPage,
    }
    /** @todo only fetch admin users if current user type === admin */
    fetchUsers(defaultParams)
  }

  public handleNext = () =>
    this.props.setCurrentPage(this.props.currentPage + 1)

  public handlePrevious = () =>
    this.props.setCurrentPage(this.props.currentPage - 1)

  public Header = () => {
    const { url, redirect, currentUserId, usersSchemaMethods } = this.props
    const redirectTo = () => redirect(`${url}/${currentUserId}`)
    const createUserUrl = () => redirect(`${url}/new`)
    return (
      <>
        <Box justify="between" direction="row" className="page-header">
          <Text tag="h1" color="white" className="page-title">
            Users
          </Text>
          <div className="buttonContainer">
            <Button
              styleType="secondary"
              label="View My Profile"
              onClick={redirectTo}
            />
            {usersSchemaMethods?.includes('POST') && (
              <Button
                styleType="primary"
                label="+ Create User"
                onClick={createUserUrl}
              />
            )}
          </div>
        </Box>
      </>
    )
  }

  public Content = () => {
    const { userData, isLoadingUsers, pages, currentPage } = this.props
    return (
      <DataTable
        manual={true}
        fitContainer={true}
        data={userData}
        columns={this.userColumns()}
        loading={isLoadingUsers}
        pageSize={userData.length}
        pages={pages.next ? currentPage + 1 : currentPage}
        page={currentPage}
        onNext={this.handleNext}
        onPrevious={this.handlePrevious}
      />
    )
  }

  public userColumns = () => {
    const { redirect, url, usersSchemaMethods } = this.props
    return [
      {
        Header: 'Username',
        accessor: 'username',
        Cell: (row: any) => {
          const redirectTo = () => redirect(`${url}/${row.original.id}`)
          return usersSchemaMethods?.includes('POST') ? (
            <Anchor onClick={redirectTo}>{row.value}</Anchor>
          ) : (
            row.value
          )
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row: any) => {
          return (
            <Text tag="span" color="inherit">
              {row.original.user_email}
            </Text>
          )
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (row: any) => {
          const className = 'capitalize'
          return (
            <Text className={className} tag="span" color="inherit">
              {row.original.type
                ? splitStringAndUpperFirst(row.original.type, '_')
                : ''}
            </Text>
          )
        },
      },
    ]
  }

  public render(): JSX.Element | null {
    const { className } = this.props
    return (
      <div className={className}>
        <UsersTemplate Header={this.Header} Content={this.Content} />
      </div>
    )
  }
}

const StyledUsersPage = styled(UsersPage)`
  width: 100%;
  overflow: scroll;

  span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .page-header {
    margin-bottom: 10px;
    align-items: center;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
`

export default StyledUsersPage
