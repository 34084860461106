import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { history } from '../state/store'
import { sitesSelectors } from './../state/modules/sites'
import { createRouteSimpleFactory, Loader } from './components'
import 'typeface-lato'
import { Box } from 'grommet'
import { uiAppActions, uiAppSelectors } from '../state/modules/ui'
import { authSelectors } from '../state/modules/auth'
import RoutesGate from './routes/RoutesGate'
import { TRoute } from './routes/types'
import { publicRoutes } from './routes/publicRoutes'
import { privateRoutes } from './routes/privateRoutes'
import AppLoader from './routes/AppLoader'

const Routes: FC = (props: any) => {
  const dispatch = useDispatch()
  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)
  const isAppLoading = useSelector(uiAppSelectors.isLoading)
  const isAppBooted = useSelector(uiAppSelectors.isBooted)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated && !isAppLoading && !isAppBooted) {
      dispatch(uiAppActions.init())
    }
  }, [isAuthenticated, isAppLoading, isAppBooted, dispatch])

  const createRoute = createRouteSimpleFactory(
    props,
    currentSitePath,
    isAppLoading,
  )

  const renderPublicRoutes = () =>
    publicRoutes.map((r: TRoute) =>
      createRoute(r.path, r.Component(), r.path, r.exact, r.title),
    )

  const renderPrivateRoutes = () =>
    privateRoutes.map((r: TRoute) =>
      createRoute(r.path, r.Component(), r.path, r.exact, r.title, true),
    )

  if (isAuthenticated && isAppLoading) {
    return <AppLoader />
  }

  return (
    <React.Suspense
      fallback={
        <Box height="100vh">
          <Loader text="Loading..." ms={300} />
        </Box>
      }
    >
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          {isAppBooted && <RoutesGate privateRoutes={privateRoutes} />}
          <Switch>
            {renderPublicRoutes()}
            {renderPrivateRoutes()}
          </Switch>
        </QueryParamProvider>
      </Router>
    </React.Suspense>
  )
}

export default Routes
