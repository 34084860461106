import * as React from 'react'
import { Grommet, Box, TipProps } from 'grommet'
import styled from 'styled-components'
import Button from '../../atoms/Button/Button'
import classNames from 'classnames'
import appBarIconsSvg from '../../../../assets/icons/app-bar-icons.svg'

export interface IAppBarIcon {
  key: string
  title: TipProps | string
  icon: string
  path: string
  currentPath: string
}
interface IIconProps {
  className?: string
  icons: IAppBarIcon[]
  redirect?(path: string): void
}
interface IAppBarProps extends IIconProps {
  children?: any
}

const IconSvg = ({ id }: { id: string }) => (
  <svg className="svg-letter" width={38} height={38}>
    <use href={`${appBarIconsSvg}#${id}`} />
  </svg>
)

const Icons = (props: IIconProps) => {
  const handleClick = (path: string) => props.redirect && props.redirect(path)
  const icons = props.icons.map((icon, i) => {
    const path = icon.path
    const page = path.split('/').pop()
    // const ButtonIcon = () => <span style={{ background: `url(${icon.icon}) no-repeat center` }} />;

    return (
      <Button
        className={classNames('app-bar-icon', {
          'app-bar-icon--active': icon.path === icon.currentPath,
        })}
        tip={icon.title}
        styleType="plain"
        key={i}
        data-test-id={page}
        icon={
          <IconSvg
            id={
              icon.path === icon.currentPath ? `${icon.icon}-active` : icon.icon
            }
          />
        }
        onClick={handleClick.bind(null, icon.path)}
      />
    )
  })
  return (
    <>
      <Box direction="row" className={props.className}>
        {icons}
      </Box>
    </>
  )
}

const StyledIcons = styled(Icons)`
  width: 100%;
  justify-content: flex-end;

  .app-bar-icon {
    margin-left: 4px;
    background: transparent;
    color: #0c3e5b;
  }

  .app-bar-icon--active {
    color: #fff;
  }

  .button {
    margin-right: 10px;
  }

  .app-bar-actions button {
    margin-left: 10px;
  }
`

export default (props: IAppBarProps) => {
  return (
    <Grommet>
      <Box
        alignContent="start"
        justify="between"
        direction="row-responsive"
        align="center"
        margin={{ bottom: '10px' }}
      >
        <Box
          alignContent="start"
          justify="between"
          direction="row-responsive"
          align="center"
        >
          {props.children}
        </Box>
        <StyledIcons
          className="app-bar-actions"
          icons={props.icons}
          redirect={props.redirect}
        />
      </Box>
    </Grommet>
  )
}
