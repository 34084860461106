import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, Redirect } from 'react-router'
import { sitesSelectors } from '../../state/modules/sites'
import { TRoute } from './types'

type Props = {
  privateRoutes: TRoute[]
}

const RoutesGate = ({ privateRoutes }: Props) => {
  const matches = privateRoutes.filter(route => {
    return !!matchPath(window.location.pathname, {
      path: route.path,
      exact: true,
      strict: false,
    })
  })

  const state = useSelector((state: any) => state)

  const hasPermission =
    matches.length === 0 ||
    matches[0].permissionSelectors === undefined ||
    matches[0].permissionSelectors?.every(selector => selector(state))

  const currentSitePath = useSelector(sitesSelectors.getCurrentSitePath)

  if (!hasPermission) {
    return <Redirect to={`/${currentSitePath}/dashboard`} />
  }

  return null
}

export default RoutesGate
