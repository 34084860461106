import { createSelector } from 'reselect'
import { IAllocationsState } from './interfaces'
import { NAMESPACE } from './constants'

const getAllocationsState = (state: any): IAllocationsState => state[NAMESPACE]

export const getAllocationsSchema = createSelector(
  getAllocationsState,
  a => a.schema as { methods: string[] },
)

export const getAllocationsSchemaMethods = createSelector(
  getAllocationsSchema,
  schema => schema?.methods || [],
)

export const getAllocationIds = createSelector(
  getAllocationsState,
  a => a.allIds,
)

export const getAllocations = createSelector(
  [getAllocationsState],
  allocations => allocations.byId,
)

export const getAllocationsOrderedbyId = createSelector(
  getAllocationsState,
  a => {
    return a.allIds.map(id => a.byId[id])
  },
)

export const getAllocationsByTransactionId = createSelector(
  [getAllocationsOrderedbyId],
  allocations => (transactionId: number) => {
    return allocations.filter(
      allocation => allocation.transactions === transactionId,
    )
  },
)

export const getAllocationById = createSelector(
  [getAllocationsState],
  allocations => (id: number) => {
    return allocations.byId?.[id]
  },
)

export const isLoading = createSelector(
  [getAllocationsState],
  allocations => allocations.loading,
)

export const getPages = createSelector(
  getAllocationsState,
  allocations => allocations.pages,
)
