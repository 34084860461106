import { Box } from 'grommet'
import React from 'react'
import styled from 'styled-components'
import { Loader } from '../components'
import bg from '../../assets/images/bg-dark.svg'

type Props = {}

const AppLoader = ({ className }: { className?: string }) => {
  return (
    <Box height="100vh" className={className}>
      <Loader text="Initiating dashboard..." ms={300} />
    </Box>
  )
}

const StyledAppLoader = styled(AppLoader)`
  background-color: #063e5a;
  background-image: url(${bg});
  background-position: top right;
  background-repeat: no-repeat;
`

export default StyledAppLoader
