import { ofType } from 'redux-observable'
import { AnyAction } from 'redux'
import { Observable, of, iif, zip } from 'rxjs'
import {
  mergeMap,
  mapTo,
  map,
  switchMap,
  concatMap,
  pluck,
  ignoreElements,
} from 'rxjs/operators'
import {
  httpError,
  optionsRequest,
  catchRestError,
  get,
} from '../../../utils/http'
import { first, get as _get } from 'lodash'
import * as actions from './actions'
import { authActions, authSelectors } from '../../auth'
import { injectCurrentSitePath } from '../../../utils/general'
import { bookingActions, bookingConstants } from '../../bookings'
import RestfulResult from '../../../utils/RestfulResult'
import { channelActions, channelConstants } from '../../channels'
import { currencyActions, currencyConstants } from '../../currency'
import { statementActions, statementConstants } from '../../statements'
import { transactionsActions, transactionsConstants } from '../../transactions'
import { usersActions, usersConstants } from '../../users'
import { supplierActions, supplierConstants } from '../../suppliers'
import { sitesActions, sitesConstants, sitesSelectors } from '../../sites'
import { allocationsActions, allocationsConstants } from '../../allocations'
import { ledgerActions, ledgerConstants } from '../../ledger'
import { batchActions, batchConstants } from '../../batches'
import { invoicesActions, invoicesConstants } from '../../invoices'
import { payoutActions, payoutConstants } from '../../payouts'
import { paymentsActions, paymentsConstants } from '../../payments'
import { history, redirect } from '../../../../utils/router'
import { matchPath } from 'react-router'
import { forkEpic } from '../../../../utils/epics'
import { requestSitesEpic } from '../../sites/epics'
import { privateRoutes } from '../../../../views/routes/privateRoutes'

/**
 * Initialize app
 * - Fetch all schema data
 */
interface ISchema {
  endpoint: string
  params?: any
  action: (...args: any[]) => AnyAction
  successAction: (...args: any[]) => AnyAction
}

const schemas: ISchema[] = [
  {
    endpoint: allocationsConstants.ENDPOINTS.BASE,
    action: allocationsActions.requestAllocationsSchema,
    successAction: allocationsActions.requestAllocationsSchemaSuccess,
  },
  {
    endpoint: batchConstants.ENDPOINTS.BASE,
    action: batchActions.requestBatchesSchema,
    successAction: batchActions.requestBatchesSchemaSuccess,
  },
  {
    endpoint: bookingConstants.ENDPOINTS.BASE,
    params: { ui: true },
    action: bookingActions.requestBookingsSchema,
    successAction: bookingActions.requestBookingsSchemaSuccess,
  },
  {
    endpoint: channelConstants.ENDPOINTS.BASE,
    params: { ui: true },
    action: channelActions.requestChannelsSchema,
    successAction: channelActions.requestChannelsSchemaSuccess,
  },
  {
    endpoint: currencyConstants.ENDPOINTS.BASE,
    action: currencyActions.requestCurrencyData,
    successAction: currencyActions.requestCurrencyDataSuccess,
  },
  {
    endpoint: invoicesConstants.ENDPOINTS.BASE,
    action: invoicesActions.requestInvoicesSchema,
    successAction: invoicesActions.requestInvoicesSchemaSuccess,
  },
  {
    endpoint: ledgerConstants.ENDPOINTS.BASE,
    action: ledgerActions.requestLedgerSchema,
    successAction: ledgerActions.requestLedgerSchemaSuccess,
  },
  {
    endpoint: payoutConstants.ENDPOINTS.BASE,
    action: payoutActions.requestPayoutsSchema,
    successAction: payoutActions.requestPayoutsSchemaSuccess,
  },
  {
    endpoint: paymentsConstants.ENDPOINTS.BASE,
    action: paymentsActions.requestPaymentsSchema,
    successAction: paymentsActions.requestPaymentsSchemaSuccess,
  },
  {
    endpoint: sitesConstants.ENDPOINTS.BASE,
    params: { ui: true },
    action: sitesActions.requestSitesSchema,
    successAction: sitesActions.requestSitesSchemaSuccess,
  },
  {
    endpoint: statementConstants.ENDPOINTS.BASE,
    action: statementActions.requestStatementsSchema,
    successAction: statementActions.requestStatementsSchemaSuccess,
  },
  {
    endpoint: supplierConstants.ENDPOINTS.BASE,
    action: supplierActions.requestSuppliersSchema,
    successAction: supplierActions.requestSuppliersSchemaSuccess,
  },
  {
    endpoint: transactionsConstants.ENDPOINTS.BASE,
    action: transactionsActions.requestTransactionsSchema,
    successAction: transactionsActions.requestTransactionsSchemaSuccess,
  },
  {
    endpoint: usersConstants.ENDPOINTS.BASE,
    action: usersActions.requestUsersSchema,
    successAction: usersActions.requestUsersSchemaSuccess,
  },
]

export const initAppSchemas = (
  action$: Observable<AnyAction>,
  state$: any,
  dependencies: any,
): any =>
  action$.pipe(
    ofType(actions.init),
    mergeMap(() => {
      const zipActions: Observable<RestfulResult>[] = []

      schemas.forEach(schema =>
        zipActions.push(
          of(schema).pipe(
            map(() => (schema.params ? { params: schema.params } : {})),
            optionsRequest(
              injectCurrentSitePath(schema.endpoint, state$.value),
              true,
            ),
            map((response: RestfulResult) =>
              response.mapOrFail((p: any) =>
                of(p).pipe(
                  mapTo(schema.successAction(p, response.getContext())),
                ),
              ),
            ),
            catchRestError(schema.action.toString()),
          ),
        ),
      )

      return zip(...zipActions)
    }),
    /**
     * Handle site selection logic before booting app
     */
    mergeMap((_actions: any) => {
      /**
       * Find the private route that matches the current URL
       */
      const match = privateRoutes
        .map(pr => {
          const pathname = history.location.pathname
          const match = matchPath(pathname, {
            path: pr.path,
            exact: true,
          })
          return match
        })
        .find(match => !!match)

      const sitePath = _get(match, 'params.sitePath', false)
      const site = sitesSelectors.getSiteByPath(state$.value)(sitePath)
      const isAuthenticated = authSelectors.isAuthenticated(state$.value)

      // Exit early if not authenticated
      if (!isAuthenticated) {
        return []
      }

      /**
       * If the site in URL is found in the state
       * we can skip the requestSitesEpic
       * and just select the site
       */
      if (site) {
        return zip(..._actions, of(sitesActions.siteSelected(site)))
      }

      /**
       * If the site in URL is not found in the state
       * we need to request the site
       * and select it after the request is done
       */
      if (sitePath) {
        return forkEpic(
          requestSitesEpic,
          dependencies,
          state$,
          sitesActions.requestSites({ site: sitePath }),
        ).pipe(
          concatMap(res =>
            of(res).pipe(
              pluck('payload'),
              mergeMap((payload: any) => {
                const site = first(payload)
                if (site) {
                  _actions.push(of(sitesActions.requestSitesSuccess(payload)))
                  _actions.push(of(sitesActions.siteSelected(site)))
                }
                return zip(..._actions)
              }),
            ),
          ),
        )
      }

      /**
       * Zip all actions and forward them to the next operator
       */
      return zip(..._actions)
    }),
    mergeMap((_actions: any) => {
      return [..._actions, actions.booted()]
    }),
  )

export const handleRedirect = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(actions.redirect),
    map((action: any) => {
      redirect(action.payload)
    }),
    ignoreElements(),
  )

export const setLoginLoadingState = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(authActions.requestToken),
    mapTo(actions.setLoginLoadingState(true)),
  )

export const handleLoginErrors = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType([httpError, authActions.loggedOut]),
    mergeMap((action: any) =>
      iif(() => action.meta.action === authActions.requestToken.toString())
        ? of(action).pipe(
            mergeMap(() => [
              actions.setLoginLoadingState(false),
              actions.setLoginErrors(action.payload.response),
            ]),
          )
        : of(undefined),
    ),
  )

export const setPasswordResetSent = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(authActions.requestPasswordForgotSuccess),
    mapTo(actions.setPasswordResetSent(true)),
  )

export const setPasswordResetSuccess = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(authActions.requestPasswordResetSuccess),
    mapTo(actions.setPasswordResetSuccess(true)),
  )

// Fetch channels where account_mode_exclude=test
// Used to hide analytics page if no results
export const fetchNonTestChannelsOnSiteSelection = (
  action$: Observable<AnyAction>,
  state$: any,
): any =>
  action$.pipe(
    ofType(sitesActions.siteSelected),
    switchMap(() =>
      of({}).pipe(
        map(() => ({
          params: {
            account_mode_exclude: ['test', 'protection-only', 'closed'],
            per_page: 1,
          },
        })),
        get(
          injectCurrentSitePath(channelConstants.ENDPOINTS.BASE, state$.value),
          true,
        ),
        mergeMap((res: RestfulResult) =>
          res.mapOrFail((p: any) =>
            of(p).pipe(
              map((channels: any) => {
                const hasNonTestChannels = channels.length > 0
                return hasNonTestChannels
                  ? actions.setNonTestChannelsExist(true)
                  : actions.setNonTestChannelsExist(false)
              }),
            ),
          ),
        ),
        catchRestError(sitesActions.siteSelected.toString()),
      ),
    ),
  )
