import { createSelector } from 'reselect'
import { get, memoize } from 'lodash'
import { NAMESPACE } from './constants'
import { IUsersModel } from './interfaces'
import { sitesSelectors } from '../sites'

const getState = (state: any) => state

const getUsersState = (state: any): IUsersModel => state[NAMESPACE]

export const getUsersByPage = createSelector(
  [getState, getUsersState],
  (state, users) => (page: number | string) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    const usersByPage = get(users, `sites[${sitePath}].byPage[${page}]`, [])
    return usersByPage.map((userId: number) => getUserById(state)(userId))
  },
)

export const getAdminUsers = createSelector(
  [getState, getUsersState],
  users => {
    return get(users, 'admin_users.byId', {})
  },
)

export const getAdminUserById = createSelector(
  getAdminUsers,
  users => (id: number | string) => get(users, `[${id}]`, null),
)

export const getUsers = createSelector(
  [getState, getUsersState],
  (state, users) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(users, `sites[${sitePath}].byId`, {})
  },
)

export const getUserById = createSelector(
  getUsers,
  users => (id: number | string) => get(users, `[${id}]`, null),
)

export const isTwoFactorAuthEnabled = createSelector(
  [getState],
  state => (id: number | string) =>
    !!getUserById(state)(id)?.two_factor_confirmed_at,
)

export const getPages = createSelector(
  [getState, getUsersState],
  (state, users) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(users, `sites[${sitePath}].pages`, false)
  },
)

export const isLoading = createSelector(
  [getState, getUsersState],
  (state, users) => {
    const sitePath = sitesSelectors.getCurrentSitePath(state)
    return get(users, `sites[${sitePath}].isLoading`, false)
  },
)

export const getUsersSchema = createSelector(
  getUsersState,
  users => users.schema,
)

export const getUsersSchemaMethods = createSelector(
  getUsersSchema,
  schema => schema?.methods || [],
)

export const getPropertiesFromSchema = createSelector(
  getUsersSchema,
  memoize(schema => get(schema, 'schema.properties', {})),
)
