import { connect } from 'react-redux'
import { AppBar } from '../components'
// import { profile, logout, settings, search, accounts, analytics } from '../../assets/icons';
import { redirect } from '../../utils/router'
import { sitesSelectors } from '../../state/modules/sites'
import { authSelectors } from '../../state/modules/auth'
import { IAppBarIcon } from '../components/organisms/AppBar/AppBar'
import { uiAppSelectors } from '../../state/modules/ui'
import { privateRoutes } from '../routes/privateRoutes'

const getTitleTooltip = (title: string) => ({
  content: title,
  plain: true,
})

const mapStateToProps = (state: any): { icons: IAppBarIcon[] } => {
  const currentSitePath = sitesSelectors.getCurrentSitePath(state as never)
  return {
    icons: [
      {
        key: 'search',
        title: getTitleTooltip('Search'),
        icon: 'search',
        path: '/search',
        currentPath: window.location.pathname,
      },
      {
        key: 'payouts',
        title: getTitleTooltip('Accounts'),
        icon: 'accounts',
        path: '/payouts',
        currentPath: window.location.pathname,
      },
      {
        key: 'profile',
        title: getTitleTooltip('Users'),
        icon: 'profile',
        path: `/${currentSitePath}/users`,
        currentPath: window.location.pathname,
      },
      {
        key: 'uploads',
        title: getTitleTooltip('Uploads'),
        icon: 'uploads',
        path: `/${currentSitePath}/uploads`,
        currentPath: window.location.pathname,
      },
      {
        key: 'suppliers',
        title: getTitleTooltip('Suppliers'),
        icon: 'suppliers',
        path: '/suppliers',
        currentPath: window.location.pathname,
      },
      {
        key: 'analytics',
        title: getTitleTooltip('Analytics'),
        icon: 'analytics',
        path: `/${currentSitePath}/analytics`,
        currentPath: window.location.pathname,
      },
      {
        key: 'settings',
        title: getTitleTooltip('Settings'),
        icon: 'settings',
        path: `/${currentSitePath}/settings`,
        currentPath: window.location.pathname,
      },
      {
        key: 'logout',
        title: getTitleTooltip('Logout'),
        icon: 'logout',
        path: '/logout',
        currentPath: window.location.pathname,
      },
    ].filter(item => {
      const privateRoute = privateRoutes.find(route => route.key === item.key)

      if (
        privateRoute?.permissionSelectors &&
        !privateRoute.permissionSelectors.every(selector => selector(state))
      ) {
        return false
      }

      return true
    }),
  }
}

const mapDispatchToProps = () => ({
  redirect: (path: string) => redirect(path),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)
