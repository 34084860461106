import React from 'react'
import cx from 'classnames'
import {
  RankedTester,
  Test,
  UISchemaElement,
  computeLabel,
  ControlProps,
  isStringControl,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import {
  VanillaRendererProps,
  withVanillaControlProps,
} from '@jsonforms/vanilla-renderers'
import SupplierSelector from '../../../../views/components/stateful/SupplierSelector'
import { Grommet } from 'grommet'
import timfinityTheme from '../../../../views/styles/timfinityTheme'
import styled from 'styled-components'
import { colors } from '../../../../views/styles/variables'
import { Button, TextInput } from '../../../../views/components'
import { useAtomValue } from 'jotai'
import { editingAtom } from '../../../../views/components/pages/SingleChannelPage/channelFormAtoms'

const EmailArrayControl = (
  props: ControlProps & VanillaRendererProps & { className?: string },
) => {
  {
    const {
      className,
      handleChange,
      schema,
      uischema,
      id,
      enabled,
      path,
      data,
      visible,
      required,
      errors,
      label,
    } = props

    const isValid = errors.length === 0
    const isVisible = !!visible && (schema as any)?.visible !== false

    const values: string[] = data ? data.split(',') : ['']
    const isEditing = useAtomValue(editingAtom)

    const isFormFieldDisabled = () => {
      if (!enabled) {
        return true
      }
      const isReadOnly = (schema as any)?.readonly === true
      if (isReadOnly) {
        return true
      }
      return false
    }

    if (!isVisible) {
      return null
    }

    return (
      <Grommet theme={timfinityTheme}>
        <div
          className={cx(className, {
            'has-error': !isValid,
          })}
          hidden={!visible}
        >
          <label htmlFor={path}>
            {computeLabel(label, required || false, false)}
          </label>
          <div className="input-control-cell">
            {values.map((email, index) => (
              <div className="multi-input field" key={index}>
                <TextInput
                  id={`${path}-${index}`}
                  name={`${path}-${index}`}
                  onChange={(e: any) => {
                    handleChange(
                      path,
                      values
                        .map((v, i) => (i === index ? e.target.value : v))
                        .join(','),
                    )
                  }}
                  disabled={isFormFieldDisabled()}
                  value={email}
                  // error={get(errors, ['primary_mail', index], '')}
                  className="field"
                  // autocomplete="off"
                />
                {isEditing && (
                  <div className="button-section">
                    <Button
                      disabled={values.length < 2}
                      label="x"
                      styleType="danger"
                      type="button"
                      onClick={() => {
                        handleChange(
                          path,
                          values.filter((v, i) => i !== index).join(','),
                        )
                      }}
                    />
                    {index === 0 && (
                      <Button
                        disabled={values.length > 4}
                        label="+"
                        styleType="primary"
                        type="button"
                        onClick={() => {
                          // arrayHelpers.insert(values.primary_mail.length, '')
                          handleChange(path, [...values, ''].join(','))
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Grommet>
    )
  }
}

const { rankWith, and } = Test

/**
 * Alternative tester for an array that also checks whether the 'table'
 * option is set.
 * @type {RankedTester}
 */
export const emailsArrayControlTester: RankedTester = rankWith(
  4,
  and(isStringControl, (uischema: UISchemaElement) => {
    return uischema?.options?.Component === 'EmailArrayControl'
  }),
)

const StyledEmailArrayControl = styled(EmailArrayControl)`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  label {
    font-size: 15px;
    padding: 8px 0;
  }

  .input-control-cell {
    width: 100%;
  }

  .email-array-control-input {
    width: 100%;
    max-width: 676px;

    > div {
      border-radius: 4px;
      border-color: ${colors.blue.dark};
    }
  }

  &.has-error {
    label {
      color: ${colors.red};
    }

    .validation {
      color: ${colors.red};
      margin-top: 5px;
    }

    input {
      border-color: ${colors.red} !important;
    }
  }
`

export default withVanillaControlProps(
  withJsonFormsControlProps(StyledEmailArrayControl),
)
