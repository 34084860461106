import * as React from 'react'
import * as yup from 'yup'
import { FormikProps, withFormik, Field } from 'formik'
import { TextInput, Button, Modal, Loader, Select } from '../..'
import styled from 'styled-components'
import { Box, Grommet } from 'grommet'
import splitStringAndUpperFirst from '../../../../utils/splitStringAndUpperFirst'
import ButtonFieldV2 from '../../molecules/ButtonFieldV2/ButtonFieldV2'
import FormField from '../../molecules/FormField/FormField'
import timfinityTheme from '../../../styles/timfinityTheme'
import { colors } from '../../../styles/variables'
import { IChannelResponse } from '../../../../state/modules/channels/interfaces'
import { IUser } from '../../../../state/modules/users/interfaces'
import ChannelSelector from '../../stateful/ChannelSelector/ChannelSelector'
import UserSitesSelector from '../../stateful/SiteSelectors/UserSitesSelector'
import ConfirmationModal from '../../organisms/ConfirmationModal/ConfirmationModal'
import ConfirmTwoFactorAuthModal from '../../../../modules/twoFactorAuthentication/components/ConfirmTwoFactorAuth/ConfirmTwoFactorAuthModal'
import DisableTwoFactorAuthModal from '../../../../modules/twoFactorAuthentication/components/ConfirmTwoFactorAuth/DisableTwoFactorAuthModal'
import ViewRecoveryCodes from '../../../../modules/twoFactorAuthentication/components/ViewRecoveryCodes'
import { titleCase } from '../../../utils/string'

/** @todo types */
interface IUserFormProps {
  user: any
  userId: any
  initialValues: any
  className: string
  currentSitePath: string
  currentUserSiteCount: number
  isSaving: any
  isCreating: any
  isDeleting: any
  isAdmin: any
  fieldsSchema: any
  usersSchemaMethods: any
  serverErrors: any
  redirect(path: string): void
  updateUser(payload: any): void
  deleteUser(payload: any): void
  allChannels: any[]
  isViewingUser: boolean
  isCurrentUser: boolean
  currentUser: any
  isTwoFactorAuthEnabled: boolean
}

interface IUserFormState {
  isEditing: boolean
  isConfirmDeleteUserModalOpen: boolean
  isEnableTwoFactorAuthModalOpen: boolean
  isDisableTwoFactorAuthModalOpen: boolean
  isRecoveryCodesModalOpen: boolean
  isConfirmUsernameOrPasswordModalOpen: boolean
}

interface IUserFields {
  name: string
  username: string
  user_email: string
  usertype: string
  password: string
  channels: []
  sites: []
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Required!'),
  username: yup.string().required('Required!'),
  user_email: yup.string().email('Invalid email address').required('Required!'),
  usertype: yup.string().required('Required!'),
  password: yup.string(),
})

class UserForm extends React.Component<
  IUserFormProps & FormikProps<IUserFields>,
  IUserFormState
> {
  public state = {
    isEditing: false,
    isConfirmDeleteUserModalOpen: false,
    isEnableTwoFactorAuthModalOpen: false,
    isDisableTwoFactorAuthModalOpen: false,
    isRecoveryCodesModalOpen: false,
    isConfirmUsernameOrPasswordModalOpen: false,
  }

  public componentDidUpdate(prevProps: IUserFormProps) {
    const { currentSitePath, userId, redirect, usersSchemaMethods } = this.props

    if (
      (prevProps.isCreating && !this.props.isCreating) ||
      (prevProps.isSaving &&
        !this.props.isSaving &&
        !prevProps.serverErrors &&
        !this.props.serverErrors)
    ) {
      this.setState({
        isEditing: false,
        isConfirmUsernameOrPasswordModalOpen: false,
      })
      redirect(`/${currentSitePath}/users/${userId}`)
    }

    if (
      (prevProps.isDeleting && !this.props.isDeleting) ||
      (prevProps.isDeleting &&
        !this.props.isDeleting &&
        !prevProps.serverErrors &&
        !this.props.serverErrors)
    ) {
      this.setState({ isConfirmDeleteUserModalOpen: false })
      redirect(`/${currentSitePath}/users`)
    }
  }

  public toggleEdit = (e?: any) => {
    if (e) {
      e.preventDefault()
    }
    this.setState({ isEditing: !this.state.isEditing })
  }

  public toggleDelete = () => {
    this.setState({
      isConfirmDeleteUserModalOpen: !this.state.isConfirmDeleteUserModalOpen,
    })
  }

  public renderButtons = () => {
    const { isViewingUser, isCurrentUser, usersSchemaMethods, user, userId } =
      this.props
    return (
      <Box direction="row" className="actions">
        {/* Viewing a user */}
        {isViewingUser &&
          !this.state.isEditing &&
          usersSchemaMethods?.includes('PUT') && (
            <Button
              styleType="secondary"
              label="Edit"
              size="small"
              type="button"
              onClick={this.toggleEdit}
            />
          )}

        {/* Editing a user */}
        {isViewingUser &&
          this.state.isEditing &&
          usersSchemaMethods?.includes('PUT') &&
          this.SaveAndCancelButtons()}

        {/* Creating a new user */}
        {!isViewingUser &&
          usersSchemaMethods?.includes('POST') &&
          this.SaveAndCancelButtons()}

        {isViewingUser &&
          usersSchemaMethods?.includes('DELETE') &&
          !isCurrentUser &&
          user.usertype !== 'client_owner' &&
          userId !== 'new' && (
            <Button
              styleType="danger"
              label="Delete"
              size="small"
              type="button"
              onClick={this.toggleDelete}
            />
          )}
      </Box>
    )
  }

  public isFormFieldDisabled = (field?: string) => {
    const { userId, isAdmin } = this.props
    if (userId === 'new') {
      return false
    }
    if (userId !== 'new' && field === 'password') {
      return this.props.isViewingUser && this.state.isEditing
    }
    if (userId !== 'new' && field === 'channels') {
      return this.props.isViewingUser && !this.state.isEditing
    }
    if (userId !== 'new' && field === 'sites') {
      return this.props.isViewingUser && !this.state.isEditing
    }

    if (userId !== 'new' && field === 'username' && isAdmin) {
      return this.props.isViewingUser && !this.state.isEditing
    }
    if (userId !== 'new' && field === 'user_email' && isAdmin) {
      return this.props.isViewingUser && !this.state.isEditing
    }
    if (userId !== 'new') {
      return true
    }

    // keeping below for when schema is finalized
    // if (field) {
    //     const isReadOnly = get(this.props.fieldsSchema, [field, 'readonly'], false);
    //     if (isReadOnly) {
    //         return true;
    //     }
    // }
    // return this.props.isViewingUser && !this.state.isEditing;
  }

  public handleCancel = () => {
    const { resetForm, redirect, currentSitePath } = this.props
    if (this.state.isEditing) {
      this.toggleEdit()
      resetForm()
    } else {
      redirect(`/${currentSitePath}/users`)
    }
  }

  public handleDelete = () => {
    const { userId, deleteUser } = this.props
    deleteUser({ id: userId })
  }

  public SaveAndCancelButtons = () => {
    const { dirty, values } = this.props

    return (
      <>
        <Button
          disabled={!dirty}
          styleType="primary"
          label="Save"
          size="small"
          type="submit"
        />
        <Button
          styleType="danger"
          label="Cancel"
          size="small"
          onClick={this.handleCancel}
        />
      </>
    )
  }

  public renderLoadingModal = () => {
    const { isSaving, isCreating } = this.props
    const InnerComponent = () => {
      let text
      if (isSaving) {
        text = 'Saving...'
      } else if (isCreating) {
        text = 'Creating user...'
      }
      return (
        <Box height="small" width="medium">
          <Loader text={text} />
        </Box>
      )
    }

    return (
      <Modal
        isOpen={isSaving || isCreating}
        showCloseIcon={false}
        InnerComponent={InnerComponent()}
      />
    )
  }

  public renderConfirmDeleteUserModal = () => {
    const { isConfirmDeleteUserModalOpen } = this.state
    return (
      <ConfirmationModal
        isOpen={isConfirmDeleteUserModalOpen}
        showCloseIcon={false}
        onClose={() =>
          this.setState({
            isConfirmDeleteUserModalOpen:
              !this.state.isConfirmDeleteUserModalOpen,
          })
        }
        title="Delete user"
        onConfirm={this.handleDelete}
      >
        <p>
          Are you sure you want to delete this user?
          <br />
          If this user account is used for an integration with TMT, deleting it
          will break that integration.
        </p>
      </ConfirmationModal>
    )
  }

  public validatePassword = (value: any) => {
    let errors: string = ''
    if (!value) {
      errors = 'Required'
    }
    if (value) {
      errors = ''
    }
    return errors
  }

  public renderPasswordField = () => {
    const {
      values,
      handleChange,
      updateUser,
      userId,
      touched,
      errors,
      isViewingUser,
      resetForm,
      isAdmin,
      isCurrentUser,
      user,
    } = this.props
    if (userId === 'new') {
      return (
        <Field
          component={TextInput}
          id="password"
          name="password"
          onChange={handleChange}
          label="Password"
          value={values.password}
          validate={() => this.validatePassword(values.password)}
          error={touched.password && errors.password}
          className="field"
        />
      )
    } else if (isViewingUser && !this.state.isEditing && isCurrentUser) {
      return (
        <Field
          component={ButtonFieldV2}
          id="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          label="Password"
          validateValue={() => this.validatePassword(values.password)}
          onClickHandler={() => {
            if (
              ['client_owner', 'member_admin'].includes(user.usertype) &&
              userId !== 'new'
            ) {
              this.setState({ isConfirmUsernameOrPasswordModalOpen: true })
            } else {
              updateUser({ password: values.password, id: userId })
            }
          }}
          className="field"
        />
      )
    } else {
      if (values.password) {
        resetForm()
      }
      return null
    }
  }

  public renderChannelsField = () => {
    const { values, setFieldValue } = this.props

    return (
      <Field
        render={() => (
          <FormField className="field" label="Channels">
            <ChannelSelector
              id="channels"
              className="user-channels"
              onChange={(value: IChannelResponse[]) => {
                setFieldValue('channels', value)
              }}
              disabled={this.isFormFieldDisabled('channels')}
              isMulti={true}
              isClearable={true}
              closeMenuOnSelect={false}
              placeholder="All channels"
              value={values.channels}
              hideOnCount={false}
            />
          </FormField>
        )}
      />
    )
  }

  public renderSitesField = () => {
    const { values, setFieldValue } = this.props
    return (
      <Field
        render={() => (
          <FormField className="field" label="Other Sites">
            <UserSitesSelector
              className="user-sites"
              onChange={(value: IUser[]) => {
                setFieldValue('sites', value)
              }}
              disabled={this.isFormFieldDisabled('sites')}
              closeMenuOnSelect={false}
              value={values.sites}
              hideOnCount={false}
            />
          </FormField>
        )}
      />
    )
  }

  public handleSubmission = (e: any) => {
    e.preventDefault()
    const { initialValues, values, user, userId, submitForm } = this.props

    if (
      initialValues.username !== values.username &&
      ['client_owner', 'member_admin'].includes(user.usertype) &&
      userId !== 'new'
    ) {
      this.setState({
        isConfirmUsernameOrPasswordModalOpen: true,
      })
    } else {
      submitForm()
    }
  }

  public render(): JSX.Element | null {
    const {
      className,
      user,
      currentUser,
      userId,
      currentUserSiteCount,
      isTwoFactorAuthEnabled,
      isCurrentUser,
      // Formik props
      submitForm,
      values,
      handleChange,
      setFieldValue,
      errors,
      touched,
    } = this.props

    return values.name || userId === 'new' ? (
      <Grommet theme={timfinityTheme}>
        <form className={className} onSubmit={this.handleSubmission}>
          <Box
            direction="row"
            justify="end"
            align="center"
            className="section-header"
          >
            {this.renderButtons()}
          </Box>
          <Box
            background="white"
            fill="horizontal"
            pad="medium"
            round="small"
            className="box"
          >
            <Field
              component={TextInput}
              id="name"
              name="name"
              onChange={handleChange}
              disabled={this.isFormFieldDisabled('name')}
              label="Name"
              value={values.name}
              error={touched.name && errors.name}
              className="field"
            />
            <Field
              component={TextInput}
              id="username"
              name="username"
              onChange={handleChange}
              disabled={this.isFormFieldDisabled('username')}
              label="Username"
              value={values.username}
              error={touched.username && errors.username}
              className="field"
            />
            <Field
              component={TextInput}
              id="user_email"
              name="user_email"
              onChange={handleChange}
              disabled={this.isFormFieldDisabled('user_email')}
              label="Email"
              value={values.user_email}
              error={touched.user_email && errors.user_email}
              className="field"
            />
            {(isCurrentUser &&
              ['administrator', 'client_owner'].includes(user.usertype)) ||
            (currentUser?.usertype === 'administrator' &&
              user.usertype === 'client_owner') ? (
              <Field
                component={TextInput}
                id="usertype"
                name="usertype"
                disabled={true}
                label="Email"
                value={titleCase(values.usertype)}
                className="field"
              />
            ) : (
              <Field
                component={Select}
                options={[
                  { label: 'Member', value: 'member_admin' },
                  { label: 'Owner', value: 'client_owner' },
                ]}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                id="usertype"
                name="usertype"
                onChange={(option: { value: any }) => {
                  setFieldValue('usertype', option.value)
                }}
                disabled={!this.state.isEditing}
                label="Type"
                value={values?.usertype}
                error={touched.usertype && errors.usertype}
                className="field"
              />
            )}
            {this.renderPasswordField()}
            <Box direction="row" align="center" className="field">
              <label htmlFor="toggle-two-factor-authentication">
                Two-Factor Authentication
              </label>
              {isTwoFactorAuthEnabled ? (
                <Box direction="row">
                  <Button
                    id="toggle-two-factor-authentication"
                    styleType="danger"
                    onClick={() =>
                      this.setState({
                        isDisableTwoFactorAuthModalOpen: true,
                      })
                    }
                    size="small"
                    label="Disable"
                  />
                  {isCurrentUser && (
                    <Button
                      id="view-recovery-codes"
                      styleType="secondary"
                      onClick={() =>
                        this.setState({
                          isRecoveryCodesModalOpen: true,
                        })
                      }
                      size="small"
                      label="View Recovery Codes"
                    />
                  )}
                </Box>
              ) : (
                <>
                  {isCurrentUser ? (
                    <Button
                      id="toggle-two-factor-authentication"
                      styleType="primary"
                      onClick={() =>
                        this.setState({
                          isEnableTwoFactorAuthModalOpen: true,
                        })
                      }
                      size="small"
                      label="Enable"
                    />
                  ) : (
                    <p>Inactive</p>
                  )}
                </>
              )}
            </Box>
            {values.usertype === 'member_admin' && this.renderChannelsField()}
            {values.usertype !== 'administrator' &&
              currentUserSiteCount > 1 &&
              this.renderSitesField()}
          </Box>
          {this.renderLoadingModal()}
          {this.renderConfirmDeleteUserModal()}
          <ConfirmTwoFactorAuthModal
            isOpen={this.state.isEnableTwoFactorAuthModalOpen}
            onClose={() =>
              this.setState({ isEnableTwoFactorAuthModalOpen: false })
            }
            showCloseIcon
            userId={userId}
          />
          <DisableTwoFactorAuthModal
            isOpen={this.state.isDisableTwoFactorAuthModalOpen}
            onClose={() =>
              this.setState({ isDisableTwoFactorAuthModalOpen: false })
            }
            showCloseIcon
            userId={userId}
          />
          <ViewRecoveryCodes
            isOpen={this.state.isRecoveryCodesModalOpen}
            onClose={() => this.setState({ isRecoveryCodesModalOpen: false })}
            showCloseIcon
            userId={userId}
          />
          <ConfirmationModal
            isOpen={this.state.isConfirmUsernameOrPasswordModalOpen}
            onClose={() =>
              this.setState({ isConfirmUsernameOrPasswordModalOpen: false })
            }
            showCloseIcon={false}
            title="Are you sure you want to do this?"
            onConfirm={submitForm}
          >
            <p>
              If this user account is used for an integration with TMT, changing
              its username or password will break that integration.
            </p>
          </ConfirmationModal>
        </form>
      </Grommet>
    ) : null
  }
}

const StyledUserForm = styled(UserForm)`
  label {
    width: 300px;
    margin-right: 20px;
  }

  .box > div {
    margin-bottom: 10px;
  }

  .field {
    height: 43px;

    label + div {
      width: auto;
      min-width: 600px;
    }
    label + button {
      min-width: 600px;
      max-width: 600px;
      overflow: scroll;
      height: inherit;
      background: ${colors.grey.lighter};
    }

    .user-channels,
    .user-sites {
      width: 100%;
      max-width: 600px;
      > div {
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.33);
      }
    }

    .error {
      margin-left: 20px;
    }
  }

  #toggle-two-factor-authentication {
    margin-right: 10px;
  }

  tbody {
    max-height: fit-content;
  }

  [disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }

  .error {
    color: ${colors.red};
  }
`

const sanitizeValues = (values: any) => {
  const channels =
    values.channels.length > 0
      ? values.channels.map((channel: any) => ({ id: channel.id }))
      : []
  const sites =
    values.sites.length > 0
      ? values.sites.map((site: any) => ({ id: site.id }))
      : []
  return { ...values, channels, sites }
}

export default withFormik({
  enableReinitialize: true,
  validationSchema,
  validateOnBlur: true,
  mapPropsToValues: (props: any) => props.initialValues,
  handleSubmit: (values: any, bag: any) => {
    const { userId, createUser, updateUser } = bag.props
    const sanitizedValues = sanitizeValues(values)

    if (userId === 'new') {
      createUser(sanitizedValues)
    } else {
      updateUser({ ...sanitizedValues, id: userId })
    }
  },
})(StyledUserForm)
