import { createActions } from 'redux-actions'
import { NAMESPACE } from './constants'
import { IRequestPaymentsParams, IPayment } from './interfaces'

export const {
  [NAMESPACE]: {
    requestPaymentsSchema,
    requestPaymentsSchemaSuccess,
    requestPayments,
    requestPaymentsSuccess,
    requestPayment,
    requestPaymentSuccess,
    deletePayment,
    deletePaymentSuccess,
  },
} = createActions({
  [`${NAMESPACE}/request_payments_schema`]: (payload: any) => payload,
  [`${NAMESPACE}/request_payments_schema_success`]: (payload: any) => payload,
  [`${NAMESPACE}/request_payments`]: (payload: IRequestPaymentsParams) =>
    payload,
  [`${NAMESPACE}/request_payments_success`]: [
    (payload: IPayment[]) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/request_payment`]: (payload: any) => payload,
  [`${NAMESPACE}/request_payment_success`]: (payload: IPayment) => payload,
  [`${NAMESPACE}/delete_payment`]: [
    (payload: any) => payload,
    (payload: any, meta: any) => meta,
  ],
  [`${NAMESPACE}/delete_payment_success`]: [
    (payload: any) => payload,
    (payload: any, meta: any) => meta,
  ],
})
