import React from 'react'
import * as uiAppSelectors from '../../state/modules/ui/app/selectors'
import { titlePrefix } from './constants'
import { TRoute } from './types'

export const privateRoutes: TRoute[] = [
  {
    key: 'dashboard',
    path: '/:sitePath/dashboard',
    exact: true,
    title: `${titlePrefix} | Dashboard`,
    Component: () =>
      React.lazy(
        () => import('../components/pages/DashboardPage/DashboardPage'),
      ),
  },
  {
    key: 'channels',
    path: '/:sitePath/channels',
    exact: true,
    title: `${titlePrefix} | Channels`,
    permissionSelectors: [uiAppSelectors.hasChannelPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/ChannelsPage/ChannelsPage')),
  },
  {
    key: 'newChannel',
    path: '/:sitePath/new/channel',
    exact: true,
    title: `${titlePrefix} | New Channel`,
    permissionSelectors: [uiAppSelectors.hasChannelPermissions],
    Component: () =>
      React.lazy(() => import('../containers/SingleChannelPageContainer')),
  },
  {
    key: 'singleChannel',
    path: '/:sitePath/channels/:channelId',
    exact: true,
    title: `${titlePrefix} | Channel`, // overwritten in single channel component
    permissionSelectors: [uiAppSelectors.hasChannelPermissions],
    Component: () =>
      React.lazy(() => import('../containers/SingleChannelPageContainer')),
  },
  {
    key: 'bookings',
    path: '/:sitePath/bookings',
    exact: true,
    title: `${titlePrefix} | Bookings`,
    permissionSelectors: [uiAppSelectors.hasBookingPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/BookingsPage/BookingsPage')),
  },
  {
    key: 'singleBooking',
    path: '/:sitePath/bookings/:id',
    exact: true,
    title: `${titlePrefix} | Booking`, // overwritten in single booking component
    permissionSelectors: [uiAppSelectors.hasBookingPermissions],
    Component: () =>
      React.lazy(() => import('../containers/SingleBookingPageContainer')),
  },
  {
    key: 'transactions',
    path: '/:sitePath/transactions',
    exact: true,
    title: `${titlePrefix} | Transactions`,
    permissionSelectors: [uiAppSelectors.hasTransactionPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/TransactionsPage/TransactionsPage'),
      ),
  },
  {
    key: 'singleTransaction',
    path: '/:sitePath/transactions/:id',
    exact: true,
    title: `${titlePrefix} | Transaction`, // overwritten in single transaction component
    permissionSelectors: [uiAppSelectors.hasTransactionPermissions],
    Component: () =>
      React.lazy(() => import('../containers/SingleTransactionsPageContainer')),
  },
  {
    key: 'allocations',
    path: '/:sitePath/allocations',
    exact: true,
    title: `${titlePrefix} | Allocations`,
    permissionSelectors: [uiAppSelectors.hasAllocationPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/AllocationsPage/AllocationsPage'),
      ),
  },
  {
    key: 'singleAllocation',
    path: '/:sitePath/allocations/:id',
    exact: true,
    title: `${titlePrefix} | Allocation`, // overwritten in single allocation component
    permissionSelectors: [uiAppSelectors.hasAllocationPermissions],
    Component: () =>
      React.lazy(
        () =>
          import(
            '../components/pages/SingleAllocationsPage/SingleAllocationsPage'
          ),
      ),
  },
  {
    key: 'payments',
    path: '/:sitePath/payments',
    exact: true,
    title: `${titlePrefix} | Payments`,
    permissionSelectors: [uiAppSelectors.hasPaymentPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/PaymentsPage/PaymentsPage')),
  },
  {
    key: 'invoices',
    path: '/:sitePath/invoices',
    exact: true,
    title: `${titlePrefix} | Invoices`,
    Component: () =>
      React.lazy(() => import('../components/pages/InvoicesPage/InvoicesPage')),
  },
  {
    key: 'singleInvoice',
    path: '/:sitePath/invoices/:id',
    exact: true,
    title: `${titlePrefix} | Invoice`, // overwritten in single invoice component
    Component: () =>
      React.lazy(
        () => import('../components/pages/SingleInvoicePage/SingleInvoicePage'),
      ),
  },
  {
    key: 'statements',
    path: '/:sitePath/statements',
    exact: true,
    title: `${titlePrefix} | Statements`,
    permissionSelectors: [uiAppSelectors.hasStatementPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/StatementsPage/StatementsPage'),
      ),
  },
  {
    key: 'singleStatement',
    path: '/:sitePath/statements/:id',
    exact: true,
    title: `${titlePrefix} | Statement`, // overwritten in single statement component
    permissionSelectors: [uiAppSelectors.hasStatementPermissions],
    Component: () =>
      React.lazy(() => import('../containers/SingleStatementPageContainer')),
  },
  {
    key: 'ledger',
    path: '/:sitePath/ledger',
    exact: true,
    title: `${titlePrefix} | Ledger`,
    permissionSelectors: [uiAppSelectors.hasLedgerPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/LedgerPage/LedgerPage')),
  },
  {
    key: 'search',
    path: '/search',
    exact: true,
    title: `${titlePrefix} | Search`,
    permissionSelectors: [uiAppSelectors.hasSearchPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/GlobalSearchPage/GlobalSearchPage'),
      ),
  },
  {
    key: 'profile',
    path: '/profile',
    exact: true,
    title: `${titlePrefix} | Profile`,
    permissionSelectors: [uiAppSelectors.hasProfilePermissions],
    Component: () =>
      React.lazy(() => import('../containers/ProfilePageContainer')),
  },
  {
    key: 'payouts',
    path: '/payouts',
    exact: true,
    title: `${titlePrefix} | Payouts`,
    permissionSelectors: [uiAppSelectors.hasPayoutsPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/PayoutsPage/PayoutsPage')),
  },
  {
    key: 'singlePayout',
    path: '/payouts/:id',
    exact: true,
    title: `${titlePrefix} | Payout`, // overwritten in single payout component
    Component: () =>
      React.lazy(
        () =>
          import(
            '../components/pages/PayoutsPage/SinglePayoutPage/SinglePayoutPage'
          ),
      ),
  },
  {
    key: 'payoutBatches',
    path: '/payouts/batches/:batch',
    exact: true,
    title: `${titlePrefix} | Batches`, // overwritten in batch component
    Component: () =>
      React.lazy(
        () =>
          import(
            '../components/pages/PayoutsPage/SingleBatchPage/SingleBatchPage'
          ),
      ),
  },
  {
    key: 'settings',
    path: '/:sitePath/settings',
    exact: true,
    title: `${titlePrefix} | Settings`,
    permissionSelectors: [uiAppSelectors.hasSettingsPermissions],
    Component: () =>
      React.lazy(() => import('../components/pages/SettingsPage/SettingsPage')),
  },
  {
    key: 'analytics',
    path: '/:sitePath/analytics',
    exact: true,
    title: `${titlePrefix} | Analytics`,
    Component: () =>
      React.lazy(
        () => import('../components/pages/AnalyticsPage/AnalyticsPage'),
      ),
  },
  {
    key: 'newSite',
    path: '/sites/new',
    exact: true,
    title: `${titlePrefix} | New Site`,
    Component: () =>
      React.lazy(() => import('../components/pages/SettingsPage/SettingsPage')),
  },
  {
    key: 'users',
    path: '/:sitePath/users',
    exact: true,
    title: `${titlePrefix} | Users`,
    Component: () =>
      React.lazy(() => import('../containers/UsersPageContainer')),
  },
  {
    key: 'singleUser',
    path: '/users/:id',
    exact: true,
    title: `${titlePrefix} | User`, // overwritten in single user component
    Component: () =>
      React.lazy(() => import('../containers/SingleUsersPageContainer')),
  },
  {
    key: 'siteSingleUser',
    path: '/:sitePath/users/:id',
    exact: true,
    title: `${titlePrefix} | User`, // overwritten in single user component
    Component: () =>
      React.lazy(() => import('../containers/SingleUsersPageContainer')),
  },
  {
    key: 'singleSupplier',
    path: '/suppliers/:id',
    exact: true,
    title: `${titlePrefix} | Suppliers`, // overwritten in single supplier component
    permissionSelectors: [uiAppSelectors.hasSupplierPermissions],
    Component: () =>
      React.lazy(
        () =>
          import('../components/pages/SingleSuppliersPage/SingleSuppliersPage'),
      ),
  },
  {
    key: 'suppliers',
    path: '/suppliers',
    exact: true,
    title: `${titlePrefix} | Suppliers`,
    permissionSelectors: [uiAppSelectors.hasSupplierPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/SuppliersPage/SuppliersPage'),
      ),
  },
  {
    key: 'uploads',
    path: '/:sitePath/uploads',
    exact: true,
    title: `${titlePrefix} | My Uploads`,
    permissionSelectors: [uiAppSelectors.hasUploadPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/MyUploadsPage/MyUploadsPage'),
      ),
  },
  {
    key: 'singleUpload',
    path: '/:sitePath/upload/:id',
    exact: true,
    title: `${titlePrefix} | Upload Progress`,
    permissionSelectors: [uiAppSelectors.hasUploadPermissions],
    Component: () =>
      React.lazy(
        () => import('../components/pages/UploadCsvPage/UploadCsvPage'),
      ),
  },
  {
    key: 'logout',
    path: '/logout',
    exact: true,
    title: `${titlePrefix} | Logout`,
    Component: () =>
      React.lazy(() => import('../containers/LogoutPageContainer')),
  },
  {
    key: 'fourOhFour',
    path: '*',
    exact: true,
    title: `${titlePrefix} | Page Not Found`,
    Component: () =>
      React.lazy(() => import('../components/pages/404Page/404Page')),
  },
]
