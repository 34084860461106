import { createSelector } from 'reselect'
import { ISuppliersState } from './interfaces'
import { NAMESPACE } from './constants'
import { get } from 'lodash'

const getSuppliersState = (state: any): ISuppliersState => state[NAMESPACE]

export const getSuppliersSchema = createSelector(
  getSuppliersState,
  s => s.schema,
)

export const getSuppliersSchemaMethods = createSelector(
  getSuppliersSchema,
  schema => schema?.methods || [],
)

export const getSupplierCategoriesForSelectFilter = createSelector(
  getSuppliersSchema,
  (schema: any) => {
    return schema?.schema.properties.category.oneOf.map((item: any) => ({
      value: item.const,
      label: item.title,
    }))
  },
)

export const getSupplierIds = createSelector(getSuppliersState, s => s.allIds)

export const getSuppliers = createSelector(
  [getSuppliersState],
  suppliers => suppliers.byId,
)

export const getAllSuppliers = createSelector(
  [getSuppliersState],
  suppliers => suppliers.allSuppliers,
)

export const getSuppliersOrderedbyId = createSelector(getSuppliersState, s => {
  return s.allIds.map(id => s.byId[id])
})

// export const getSupplierById = createSelector(getSuppliers, (suppliers) => (id: string) => get(suppliers, [id]));

export const getSupplierById = createSelector(
  getAllSuppliers,
  suppliers => (id: string) => get(suppliers, [id]),
)

export const isLoading = createSelector(getSuppliersState, suppliers => {
  return get(suppliers, 'isLoading', false)
})

export const getPages = createSelector(
  getSuppliersState,
  suppliers => suppliers.pages,
)
