import * as React from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PrivateRoute from './PrivateRoute'
import { UnauthenticatedLayout, AuthenticatedLayout, Loader } from '..'
import { Box } from 'grommet'
import { useGA4React } from 'ga-4-react'
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels'
import { GA_ID } from '../../../state/constants'

const useGaPushPageView = (
  pathname: string,
  title: string,
  isAppLoading: boolean,
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const ga4React: void | GA4ReactResolveInterface = useGA4React()
  React.useEffect(() => {
    if (!isLoading && ga4React) {
      ;(ga4React as GA4ReactResolveInterface).pageview(
        pathname,
        undefined,
        title,
      )
    }
  }, [pathname, isLoading, ga4React, title])

  React.useEffect(() => {
    if (!isAppLoading) {
      setIsLoading(false)
    }
  }, [isAppLoading])
}

export default function createRouteSimpleFactory(
  props: any,
  currentSitePath: string,
  isAppLoading: boolean,
) {
  return (
    path: string,
    Component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>,
    key?: string,
    exact: boolean = true,
    title: string = '',
    requireAuth: boolean = false,
  ) => {
    const getLayoutWrapperFragment = (cProps: any) => (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {requireAuth ? (
          <AuthenticatedLayout
            match={cProps.match}
            currentSitePath={currentSitePath}
          >
            <Component {...cProps} />
          </AuthenticatedLayout>
        ) : (
          <UnauthenticatedLayout>
            <Component {...cProps} />
          </UnauthenticatedLayout>
        )}
      </>
    )

    const ComponentLayoutWrapper =
      GA_ID && process.env.NODE_ENV === 'production'
        ? (cProps: any) => {
            useGaPushPageView(cProps.location.pathname, title, isAppLoading)
            return getLayoutWrapperFragment(cProps)
          }
        : (cProps: any) => {
            return getLayoutWrapperFragment(cProps)
          }

    return requireAuth ? (
      <PrivateRoute
        key={key || ''}
        exact={exact}
        path={path}
        component={ComponentLayoutWrapper}
        props={props.childProps}
      />
    ) : (
      <Route
        key={key || ''}
        path={path}
        exact={exact}
        component={ComponentLayoutWrapper}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        props={props.childProps}
      />
    )
  }
}
