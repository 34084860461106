import { createSelector } from 'reselect'
import { get } from 'lodash'
import * as sitesSelectors from '../../sites/selectors'
import { allocationsSelectors } from '../../allocations'
import { channelSelectors } from '../../channels'
import { bookingSelectors } from '../../bookings'
import { transactionsSelectors } from '../../transactions'
import { paymentsSelectors } from '../../payments'
import { statementSelectors } from '../../statements'
import { ledgerSelectors } from '../../ledger'
import { authSelectors } from '../../auth'
import { usersSelectors } from '../../users'
import { supplierSelectors } from '../../suppliers'

const getState = (state: any) => state

const getUIAppState = createSelector(getState, state =>
  get(state, 'ui.app', {}),
)

export const getLoginState = createSelector(getUIAppState, ui => ui.login)

export const isLoading = createSelector(
  [getState, getUIAppState],
  (state, ui) => {
    // previous site is null when the app is first loaded and then set once the main site is loaded
    // use this to determine if the app is loading and prevent a flash of the site selector or site name in header
    const previousSite = sitesSelectors.getPreviousSite(state)
    return ui.loading || previousSite === null
  },
)

export const isBooted = createSelector(getUIAppState, ui => ui.booted)

export const getForgotPasswordState = createSelector(
  getUIAppState,
  ui => ui.forgotpassword,
)

export const getResetPasswordState = createSelector(
  getUIAppState,
  ui => ui.resetpassword,
)

export const isLoggingIn = createSelector(getLoginState, ls => ls.loading)

export const isPasswordResetSent = createSelector(
  getForgotPasswordState,
  fs => fs.reset_link_sent,
)

export const isPasswordResetSuccess = createSelector(
  getResetPasswordState,
  rs => rs.reset_success,
)

export const loginErrors = createSelector(getLoginState, ls => ls.errors)

export const nonTestChannelsExist = createSelector(
  getUIAppState,
  ui => ui.nonTestChannelsExist,
)

export const hasAllocationPermissions = createSelector(
  [allocationsSelectors.getAllocationsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasBookingPermissions = createSelector(
  [bookingSelectors.getBookingsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasChannelPermissions = createSelector(
  [channelSelectors.getChannelsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasLedgerPermissions = createSelector(
  [ledgerSelectors.getLedgerSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasPaymentPermissions = createSelector(
  [paymentsSelectors.getPaymentsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasPayoutsPermissions = createSelector(
  [authSelectors.isAdmin],
  isAdmin => isAdmin,
)

/**
 * Alias for `hasPayoutsPermissions`
 */
export const hasAccountsPermissions = hasPayoutsPermissions

export const hasProfilePermissions = createSelector(
  [usersSelectors.getUsersSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasSearchPermissions = createSelector(
  [authSelectors.isAdmin],
  isAdmin => isAdmin,
)

export const hasStatementPermissions = createSelector(
  [statementSelectors.getStatementsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasSettingsPermissions = createSelector(
  [sitesSelectors.getSitesSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasSupplierPermissions = createSelector(
  [supplierSelectors.getSuppliersSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasTransactionPermissions = createSelector(
  [transactionsSelectors.getTransactionsSchemaMethods],
  methods => methods?.includes('GET'),
)

export const hasUploadPermissions = createSelector(
  [authSelectors.isAdmin, sitesSelectors.getCurrentSiteProtectionOnly],
  (isAdmin, isProtectionOnly) => isAdmin || isProtectionOnly,
)
